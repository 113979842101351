import { Settings } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import type { Action, ActionId, ActionImpl } from "kbar";
import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  Priority,
  useMatches,
} from "kbar";
import type { ReactNode } from "react";
import React, { forwardRef } from "react";

import { useColorMode } from "../../../context/ColorModeContext";

/** Common section names and parent names for the kbar Cmd-K menu */
export const CMD_K = {
  sections: {
    site: "Site",
  },
  parents: {
    preferences: "preferences",
  },
};

/** Provider for the kbar Cmd-K menu */
const CommandKMenuProvider = (props: { children: ReactNode }) => {
  const theme = useTheme();
  const { setColorMode } = useColorMode();

  const idTheme = "theme";

  const actions: Action[] = [
    {
      id: CMD_K.parents.preferences,
      name: "Preferences",
      keywords: "settings",
      icon: <Settings />,
      section: CMD_K.sections.site,
      priority: Priority.LOW,
    },
    {
      id: idTheme,
      name: "Change theme…",
      keywords: "interface color dark light",
      parent: "preferences",
    },
    {
      id: "systemTheme",
      parent: idTheme,
      name: "System",
      keywords: "system theme",
      perform: () => setColorMode("system"),
    },
    {
      id: "darkTheme",
      parent: idTheme,
      name: "Dark",
      keywords: "dark theme",
      perform: () => setColorMode("dark"),
    },
    {
      id: "lightTheme",
      parent: idTheme,
      name: "Light",
      keywords: "light theme",
      perform: () => setColorMode("light"),
    },
  ];

  const colorBg = theme.palette.background.paper;
  const colorFg = theme.palette.text.primary;

  const searchStyle = {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- styling
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.body1.fontSize,
    width: "100%",
    boxSizing: "border-box" as React.CSSProperties["boxSizing"],
    outline: "none",
    border: "none",
    background: colorBg,
    color: colorFg,
  };

  const animatorStyle = {
    maxWidth: theme.breakpoints.values.sm,
    width: "100%",
    background: colorBg,
    color: colorFg,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    boxShadow: theme.shadows[4],
  };

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner style={{ zIndex: 9999 }}>
          <KBarAnimator style={animatorStyle}>
            <KBarSearch style={searchStyle} />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {props.children}
    </KBarProvider>
  );
};

function RenderResults() {
  const { results, rootActionId } = useMatches();
  const theme = useTheme();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div
            css={{
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- styling
              padding: theme.spacing(1, 2),
              fontSize: theme.typography.subtitle2.fontSize,
              textTransform: "uppercase",
              opacity: 0.5,
            }}
          >
            {item}
          </div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId ?? ""}
          />
        )
      }
    />
  );
}

const ResultItem = forwardRef(
  (
    {
      action,
      active,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- styling
    const borderSpacing = theme.spacing(0.5);

    return (
      <div
        ref={ref}
        css={{
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- styling
          padding: theme.spacing(1, 2),
          background: active
            ? theme.palette.action.selected
            : theme.palette.background.default,
          borderLeft: `${borderSpacing} solid ${
            active ? theme.palette.primary.main : "transparent"
          }`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div
          css={{
            display: "flex",
            gap: theme.spacing(1),
            alignItems: "center",
            fontSize: theme.typography.body1.fontSize,
          }}
        >
          {action.icon && action.icon}
          <div css={{ display: "flex", flexDirection: "column" }}>
            <div>
              {action.ancestors.map((ancestor) => (
                <React.Fragment key={ancestor.id}>
                  <span
                    css={{
                      opacity: 0.5,
                      marginRight: borderSpacing,
                    }}
                  >
                    {ancestor.name}
                  </span>
                  <span
                    css={{
                      marginRight: borderSpacing,
                    }}
                  >
                    &rsaquo;
                  </span>
                </React.Fragment>
              ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span css={{ fontSize: theme.typography.caption.fontSize }}>
                {action.subtitle}
              </span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            css={{
              display: "grid",
              gridAutoFlow: "column",
              gap: theme.spacing(1),
            }}
          >
            {action.shortcut.map((sc) => (
              <kbd
                key={sc}
                css={{
                  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- styling
                  padding: theme.spacing(0.5, 1),
                  background: theme.palette.background.paper,
                  borderRadius: theme.shape.borderRadius,
                  fontSize: theme.typography.caption.fontSize,
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);
ResultItem.displayName = "ResultItem";

export default CommandKMenuProvider;
